import styled from 'styled-components';
import { MARGIN_PADDING_STYLES } from '../stylesHelpers';
import { ITypographyProps } from './types';

const TypographyStyled = styled.p.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<ITypographyProps>`
    font-family: ${({ fontFamily }) => fontFamily ?? 'Lato'};
    font-size: ${({ fontSize }) => fontSize ?? 'inherit'};
    color: ${({ color }) => color ?? 'inherit'};
    text-align: ${({ textAlign }) => textAlign ?? 'left'};
    line-height: ${({ lineHeight }) => lineHeight ?? 'normal'};
    letter-spacing: ${({ letterSpacing }) => letterSpacing ?? 'normal'};
    font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
    font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
    text-decoration: ${({ textDecoration }) => textDecoration ?? 'none'};
    text-transform: ${({ textTransform }) => textTransform ?? 'none'};
    white-space: ${({ whiteSpace }) => whiteSpace ?? 'inherit'};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    ${({ lineClamp }) => lineClamp && `-webkit-line-clamp: ${lineClamp};`}
    text-wrap: ${({ textWrap }) => textWrap ?? 'initial'};
    -webkit-box-orient: vertical;
    width: ${({ width }) => width ?? 'auto'};
    ${(props) => MARGIN_PADDING_STYLES(props)};
`;

export default TypographyStyled;
