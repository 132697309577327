import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarOperations } from '../../../common/Sidebar/duck';
import { useNavigation } from '../../../utility';
import { useIsMobile } from '../../../utility/hooks/useIsMobile';
import { RootLocation } from '../../Dashboard/DailyMenu/EditDailyMenu/EditDailyMenu';

const useKitchenAdminNavigation = (): {
    ordersLink: string;
    weeklyMenuLink: string;
    customersManagementLink: string;
    settingsLink: string;
    reportsLink: string;
    createReportLink: string;
    goToOrdersLink(): void;
    goToOrderLink(orderId: string): void;
    getOrderLink(orderId: string): string;
    getEditReportLink(reportId: string): string;
    goToCustomersManagementLink(): void;
    goToSettingsLink(): void;
    goToWeeklyMenuLink(): void;
    goToReportsLink(): void;
    goToCreateReportLink(): void;
    goToEditReportLink(reportId: string): void;
} => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    return useMemo(() => {
        const ordersLink = '/admin/vendors/orders';
        const customersManagementLink = '/admin/vendors';
        const settingsLink = '/admin/vendors/settings';
        const weeklyMenuLink = '/dashboard/dailyMenu/edit';
        const reportsLink = '/admin/vendors/reports';
        const createReportLink = '/admin/vendors/reports/new';

        const onNavigate = (url: string, shouldCloseSideBar = true) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url);
        };

        const getOrderLink = (orderId: string) => `${ordersLink}/${orderId}`;
        const getEditReportLink = (reportId: string) => `${reportsLink}/${reportId}`;
        return {
            ordersLink,
            weeklyMenuLink,
            customersManagementLink,
            settingsLink,
            reportsLink,
            createReportLink,
            goToOrdersLink: () => onNavigate(ordersLink),
            goToCustomersManagementLink: () => onNavigate(customersManagementLink),
            goToSettingsLink: () => onNavigate(settingsLink),
            goToOrderLink: (orderId: string) => onNavigate(getOrderLink(orderId)),
            getOrderLink,
            getEditReportLink,
            goToWeeklyMenuLink: () => navigation.replace(weeklyMenuLink, { page: RootLocation.SideBar }),
            goToReportsLink: () => onNavigate(reportsLink),
            goToCreateReportLink: () => onNavigate(createReportLink),
            goToEditReportLink: (reportId: string) => onNavigate(getEditReportLink(reportId)),
        };
    }, [navigation]);
};

export default useKitchenAdminNavigation;
