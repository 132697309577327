import React, { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IconProps } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useClientRect } from '../../../utility';
import useDisableMouseWheel from '../../../utility/hooks/useDisableMouseWheel';
import useHover from '../../../utility/hooks/useHover';
import { useIsMobile } from '../../../utility/hooks/useIsMobile';
import useMouseClickedOutsideElement from '../../../utility/hooks/useMouseClickOutsideElement';
import FieldErrorMessage from '../../FieldErrorMessage';
import Label from '../../Label';
import { OptionsList, PortalContainer } from './components/index';
import {
    getHourString,
    getMapHours,
    getMinuteString,
    getSortAndMapMinutes,
    getTimeOptions,
    getValidMinuteOption,
} from './utils';

const StyledTimePickerWrapper = styled.div`
    position: relative;
    background-color: var(--surface-color-light);
    border: 1px solid var(--border-color) !important;
    border-radius: 5px !important;
    white-space: nowrap;
    width: 100%;
    align-items: center;
    min-width: 150px;
    cursor: pointer;
    padding: 0.714rem 1.143rem;
`;

const StyledInput = styled.input`
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    align-items: center;
    min-width: 150px;
    cursor: pointer;
    color: var(--text-high-emphasis-color) !important;
    border: none !important;
    text-align: left;
    outline: none !important;
    margin: 0;
    line-height: 1.429rem;
    font-size: 1.143rem;
`;

const StyledIcon = styled(Icon)`
    &&& {
        background-color: var(--surface-color-light);
        box-shadow: none;
        align-self: center;
        color: var(--text-medium-emphasis-color);
        padding-right: 0.571rem;
    }
`;

const StyledLabel = styled.label`
    display: inline-block;
    color: var(--placeholder-color);
    background-color: transparent;
    padding: 0;
    margin: 0em 0em 0.28571429rem 0em;
    font-size: 0.8571rem;
    font-weight: normal;
    line-height: 1rem;
`;

const SelectorWrapper = styled.div`
    position: relative;
`;

type SelectorContainerProps = { isMobile: boolean };
const SelectorContainer = styled.div`
    position: ${({ isMobile }: SelectorContainerProps) => (isMobile ? 'relative' : 'absolute')};
    background-color: var(--surface-color-light);
    font-size: 1.143rem;
    top: 0;
    left: 50%;
    transform: ${({ isMobile }: SelectorContainerProps) => (isMobile ? '' : 'translate(-50%, 9px);')};
    width: ${({ isMobile }: SelectorContainerProps) => (isMobile ? '100%' : '21.429rem')};
    box-shadow: 0px 2px 4px 0px rgb(34 36 38 / 12%), 0px 2px 10px 0px rgb(34 36 38 / 15%);
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    height: 21.429rem;
    border-radius: 3px;
`;

const Triangle = styled.div`
    content: '';
    z-index: 2;
    margin-top: -8px;
    position: absolute;
    box-sizing: content-box;
    border: 8px solid transparent;
    top: 0;
    border-top: none;
    border-bottom-color: #f0f0f0;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: 1px;
    border-width: 8px;
`;

const Header = styled.h3`
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    text-align: center;
    padding: 0.86rem;
    font-size: 1.143rem;
    line-height: 1.714rem;
    font-weight: bold;
    margin: 0;
`;

const rootElement = document.querySelector(':root');
const opacity = '05';
const selectorBackgroundColor =
    rootElement && getComputedStyle(rootElement).getPropertyValue('--primary-color') + opacity;
const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: var(--text-high-emphasis-color);
    overflow-y: auto;

    :before {
        width: 9.143rem;
        height: 2.5rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: ':';
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        border: 1px solid var(--primary-color);
        border-radius: 3px;
        background-color: ${selectorBackgroundColor};
    }
`;

const PopperButton = styled.button`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: #e9ebeb;
    padding: 12px;
    border: none;
    margin: 1rem;
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    position: absolute;
    bottom: -75px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
`;

const StyledPopperButtonIcon = styled(Icon)`
    color: #475156;
    && {
        margin: 0;
    }
`;

const HOUR_CONTAINER_NAME = 'hour';
const MINUTE_CONTAINER_NAME = 'minute';

interface TimePickerProps {
    label?: string;
    value?: Date | null | undefined;
    onChangeSelectedTime: (newTime: Date) => void;
    earliestTime: Date;
    latestTime: Date;
    parentElementId?: string;
    icon?: IconProps;
    helpText?: string;
    placeholder?: string;
    disable?: boolean;
}

const TimePicker: React.FC<React.PropsWithChildren<TimePickerProps>> = ({
    label = '',
    value,
    onChangeSelectedTime,
    earliestTime,
    latestTime,
    parentElementId = 'site-container',
    icon,
    helpText = '',
    placeholder,
    disable,
}: TimePickerProps) => {
    const pickerRef = useRef<HTMLDivElement>(null);
    const [OptionsContainerRect, { height: optionsContainerHeight }] = useClientRect();
    const [headerRef, { height: headerContainerHeight }] = useClientRect();
    const [openTimePicker, setOpenTimePicker] = useState<boolean>(false);
    const [selectedTime, setSelectedTime] = useState(value ?? earliestTime);
    const isHoveringTimePicker = useHover(pickerRef.current);
    useMouseClickedOutsideElement(pickerRef, () => !isMobile && setOpenTimePicker(false));
    useDisableMouseWheel(openTimePicker && isHoveringTimePicker);
    const isMobile = useIsMobile();
    const { t } = useTranslation('common');
    const [timeOptions, minutes, hours] = useMemo(() => {
        const timeOptions = getTimeOptions(earliestTime, latestTime);
        const minutes = new Set<string>();
        const hours = new Set<string>();
        timeOptions.forEach((option) => {
            minutes.add(getMinuteString(option));
            hours.add(getHourString(option));
        });
        return [
            timeOptions,
            getSortAndMapMinutes(value ?? earliestTime, timeOptions, new Set<string>(minutes)),
            getMapHours(new Set<string>(hours)),
        ];
    }, [earliestTime, latestTime, value]);
    useEffect(() => {
        const checkIfValidTime = () => {
            const checkedTime = getValidMinuteOption(value ?? earliestTime, minutes);
            checkedTime && setSelectedTime(checkedTime);
        };
        checkIfValidTime();
    }, [value, minutes, openTimePicker, earliestTime]);

    const handleHourChange = (changedHour: string): void => {
        let newDate = new Date(selectedTime);
        newDate.setHours(parseInt(changedHour));
        if (newDate > timeOptions[timeOptions.length - 1]) {
            newDate = timeOptions[timeOptions.length - 1];
        } else if (newDate < timeOptions[0]) {
            newDate = timeOptions[0];
        }
        setSelectedTime(newDate);
        !isMobile && onChangeSelectedTime(newDate);
    };

    const handleMinuteChange = (changedMinute: string): void => {
        let newDate = new Date(selectedTime);
        newDate.setMinutes(parseInt(changedMinute));
        if (newDate > timeOptions[timeOptions.length - 1]) {
            newDate = timeOptions[timeOptions.length - 1];
        } else if (newDate < timeOptions[0]) {
            newDate = timeOptions[0];
        }

        setSelectedTime(newDate);
        !isMobile && onChangeSelectedTime(newDate);
    };

    const onClickSetTimeForMobileHandler = (): void => {
        onChangeSelectedTime(selectedTime);
        setOpenTimePicker(false);
    };

    const onClickCancelTimeForMobileHandler = (): void => {
        setOpenTimePicker(false);
    };

    const onClickInputHandler = (): void => (openTimePicker ? setOpenTimePicker(false) : openPickerHandler());
    const onKeyDownHandler = (e: KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Tab') setOpenTimePicker(true);
        if (e.key === 'Escape') setOpenTimePicker(false);
    };
    const onPickerKeyDownHandler = (e: KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Escape') setOpenTimePicker(false);
    };
    const openPickerHandler = () => setOpenTimePicker(true);

    const inputValue = useMemo(() => {
        return value ? `${getHourString(value)}:${getMinuteString(value)}` : placeholder;
    }, [value, placeholder]);

    const errorMessage = useMemo(() => {
        if (earliestTime.getTime() >= latestTime.getTime()) {
            console.error(
                'Earliest time can not be equal or later than latest time. Please check component prop values!',
            );
            return t('errorMessageEarliestTimeEqualLaterThenLatest');
        }
    }, [earliestTime, latestTime]);

    return (
        <>
            {label && (
                <Label isError={false} htmlFor="">
                    {label}
                </Label>
            )}
            <StyledTimePickerWrapper id="inputWrapper" onKeyDown={onKeyDownHandler} onClick={onClickInputHandler}>
                {icon ? <StyledIcon name={icon.name} id="timePickerIcon" /> : null}
                <StyledInput id="deliveryTime" value={inputValue} readOnly />
            </StyledTimePickerWrapper>
            {timeOptions?.length > 0 && openTimePicker && !disable ? (
                <SelectorWrapper>
                    <PortalContainer isMobile={isMobile} parentElementId={parentElementId}>
                        <SelectorContainer isMobile={isMobile} onKeyDown={onPickerKeyDownHandler} ref={pickerRef}>
                            {!isMobile && <Triangle />}
                            <Header ref={headerRef}>{t('time')}</Header>
                            <OptionsContainer key="optionsContainer" ref={OptionsContainerRect}>
                                <OptionsList
                                    containerName={HOUR_CONTAINER_NAME}
                                    options={hours}
                                    selectedValue={getHourString(selectedTime)}
                                    handleValueChange={handleHourChange}
                                    key="optionListHour"
                                    yContainerCenter={(optionsContainerHeight - headerContainerHeight) / 2}
                                />
                                <OptionsList
                                    containerName={MINUTE_CONTAINER_NAME}
                                    options={minutes}
                                    selectedValue={getMinuteString(selectedTime)}
                                    handleValueChange={handleMinuteChange}
                                    key="optionListMinute"
                                    alignRight
                                    yContainerCenter={(optionsContainerHeight - headerContainerHeight) / 2}
                                />
                            </OptionsContainer>
                            {isMobile && parentElementId && (
                                <ButtonContainer>
                                    <PopperButton onClick={onClickCancelTimeForMobileHandler}>
                                        <StyledPopperButtonIcon name="cancel" aria-label={t('cancelImageUpload')} />
                                    </PopperButton>
                                    <PopperButton onClick={onClickSetTimeForMobileHandler}>
                                        <StyledPopperButtonIcon name="check" aria-label={t('confirmDate')} />
                                    </PopperButton>
                                </ButtonContainer>
                            )}
                        </SelectorContainer>
                    </PortalContainer>
                </SelectorWrapper>
            ) : null}
            {helpText && <StyledLabel>{helpText}</StyledLabel>}
            {errorMessage ? <FieldErrorMessage>{errorMessage}</FieldErrorMessage> : null}
        </>
    );
};

export default TimePicker;
