import { forwardRef, SyntheticEvent } from 'react';
import styled from 'styled-components';
import SvgIcon from '../icons/SvgIcon';

type TriggerButtonProps = { show?: boolean };
const TriggerButton = styled.button`
    background-color: ${({ show }: TriggerButtonProps) =>
        show ? 'var(--primary-color-light)' : 'var(--surface-color)'};

    border-radius: 50%;
    cursor: pointer;
    width: 2.28rem;
    height: 2.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    :focus {
        border: 0.0625rem solid var(--primary-color);
        outline: none;
    }

    & path {
        fill: ${({ show }: TriggerButtonProps) =>
            show ? 'var(--primary-on-color-light)' : 'var(--text-medium-emphasis-color)'};
    }
`;
interface RoundTriggerButtonProps {
    onClick: (e: SyntheticEvent) => void;
    triggered: boolean;
}

const RoundTriggerButton = forwardRef<HTMLButtonElement, RoundTriggerButtonProps>(({ onClick, triggered }, ref) => {
    return (
        <TriggerButton onClick={onClick} show={triggered} ref={ref}>
            <SvgIcon name="EllipsisIcon" />
        </TriggerButton>
    );
});

RoundTriggerButton.displayName = 'RoundTriggerButton';

export default RoundTriggerButton;
