import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../../components';

type ContainerProps = {
    hasCallback: boolean;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
};
const Container = styled.div`
    position: absolute;
    right: ${({ right = '2rem' }: ContainerProps) => right};
    top: ${({ top = '5.7rem' }: ContainerProps) => top};
    left: ${({ left = 'auto' }: ContainerProps) => left};
    bottom: ${({ bottom = 'auto' }: ContainerProps) => bottom};
    border-radius: 0.857rem;
    max-width: 26.286rem;
    width: fit-content;
    height: auto;
    z-index: 10000;
    background-color: #fff;
    box-shadow: 0 0 3.571rem rgba(0, 0, 0, 0.15);
    cursor: ${({ hasCallback }: ContainerProps) => (hasCallback ? 'pointer' : 'auto')};
    transform: translateZ(100px);
`;

type CaretProps = {
    caretPosition?: CaretPositionType;
};
const Caret = styled.div`
    margin-top: -0.571rem;
    position: absolute;
    box-sizing: content-box;
    border: 0.571rem solid transparent;
    top: 0;
    border-top: none;
    border-bottom-color: #fff;
    ${({ caretPosition = 'end' }: CaretProps) => {
        if (caretPosition === 'start') return { left: '1.143rem' };
        if (caretPosition === 'end') return { right: '1.143rem' };
        if (caretPosition === 'center') return { left: '50%' };
    }}
    height: 0;
    width: 0.071rem;
`;

const Content = styled.div`
    margin: 1.571rem;
`;

const CloseButton = styled.button`
    margin-left: auto;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    & path {
        fill: var(--text-high-emphasis-color);
    }

    :focus-visible,
    :focus {
        outline: 0.071rem solid var(--primary-color);
        outline-offset: 0.143rem;
    }

    :focus:not(:focus-visible) {
        outline: none;
        outline-offset: 0.143rem;
    }
`;

type CaretPositionType = 'center' | 'start' | 'end';

interface TooltipProps {
    open: boolean;
    onClose(): void;
    hideCloseButton?: boolean;
    onClick?(): void;
    forwardedRef?: React.RefObject<HTMLDivElement>;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    caretPosition?: CaretPositionType;
}

const Tooltip = ({
    children,
    open,
    onClose,
    hideCloseButton,
    onClick,
    forwardedRef,
    top,
    bottom,
    left,
    right,
    caretPosition,
}: PropsWithChildren<TooltipProps>): ReactElement | null => {
    return open ? (
        <Container
            ref={forwardedRef}
            onClick={(e) => {
                e.stopPropagation();
                onClick && onClick();
            }}
            hasCallback={!!onClick}
            top={top}
            bottom={bottom}
            left={left}
            right={right}
        >
            <Caret caretPosition={caretPosition} />
            <Content>
                {!hideCloseButton && (
                    <CloseButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    >
                        <SvgIcon name="CloseIcon" />
                    </CloseButton>
                )}
                {children}
            </Content>
        </Container>
    ) : null;
};

export default Tooltip;
