import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SidebarMenuItem } from '../../../components';
import useKitchenAdminNavigation from './useKitchenAdminNavigation';

const KitchenAdminDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('VendorSettings', { keyPrefix: 'common' });
    const {
        customersManagementLink,
        goToCustomersManagementLink,
        ordersLink,
        goToOrdersLink,
        weeklyMenuLink,
        goToWeeklyMenuLink,
        settingsLink,
        goToSettingsLink,
        reportsLink,
        goToReportsLink,
    } = useKitchenAdminNavigation();

    const { pathname } = useLocation();

    const isVendorSettingsPathName =
        pathname?.includes(weeklyMenuLink) ||
        pathname?.includes(ordersLink) ||
        pathname?.includes(customersManagementLink) ||
        pathname?.includes(settingsLink) ||
        pathname?.includes(reportsLink);

    return (
        <SidebarMenuItem
            icon="VendorsSettingsIcon"
            isActive={isVendorSettingsPathName}
            onClick={goToCustomersManagementLink}
            text={t('vendorSettingsTitle')}
            isReversed
        >
            <SidebarMenuItem
                isActive={pathname?.includes(weeklyMenuLink)}
                onClick={goToWeeklyMenuLink}
                text={t('weeklyMenu')}
            />
            <SidebarMenuItem isActive={pathname?.includes(ordersLink)} onClick={goToOrdersLink} text={t('orders')} />
            <SidebarMenuItem
                isActive={pathname?.endsWith(customersManagementLink)}
                onClick={goToCustomersManagementLink}
                text={t('manageCustomers')}
            />
            <SidebarMenuItem
                isActive={pathname?.includes(settingsLink)}
                onClick={goToSettingsLink}
                text={t('settings')}
            />
            <SidebarMenuItem
                isActive={pathname?.includes(reportsLink)}
                onClick={goToReportsLink}
                text={t('scheduledReports')}
            />
        </SidebarMenuItem>
    );
};

export default KitchenAdminDynamicMenuItem;
