import type { TFunction } from 'react-i18next';
import routeConstants from '../../utility/routeConstants';

const checkIfPathNameIncludesSubdomain = (pathname: string, name: string): boolean =>
    pathname.toLowerCase().includes(name.toLowerCase());

const getNavBarHeader = (pathname: string, t: TFunction<'common'>): string | undefined => {
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.adminTenants)) return t('portalSettings');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.adminUsers)) return t('companySettings');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.adminKitchen)) return t('vendorSettings');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.articles)) return t('articles');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.editFoodMenus)) return t('editFoodMenus');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.food)) return t('food');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.meeting)) return t('meeting');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.ticketing)) return t('tickets');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.shopAtWork)) return t('eCommerce');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.wardrobe)) return t('wardrobe');
    if (checkIfPathNameIncludesSubdomain(pathname, routeConstants.events)) return t('healthAndFitness');
    else return t('dashboard');
};

export { getNavBarHeader };
