import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { default as appOperations } from '../../../duck/operations';
import { IRootStateProps } from '../../../reducers';
import { Module } from '../../api/apiTypes/portalApiTypes';
import NoAccessOrDontExist from '../../modules/NoAccessOrDontExist/NoAccessOrDontExist';
import { useNavigation } from '../../utility';
import { usePortalConfig } from '../apiQueries/useAppConfiguration';
import { ModuleContainer } from './components';

interface ModuleWrapperSelected {
    selectedLang: string;
    modules: Module[];
    selectedModule: Module | null;
    menu: MenuItem[];
}

interface MenuItem {
    value: string;
    name: string;
    children: MenuItem[];
}

const ModuleWrapperFunction: React.FC<React.PropsWithChildren<unknown>> = (): JSX.Element => {
    const { defaultModule: defaultModuleName } = usePortalConfig();
    const { modules, selectedModule, menu }: ModuleWrapperSelected = useSelector<
        IRootStateProps,
        ModuleWrapperSelected
    >((state) => ({
        modules: state.app.modules,
        selectedModule: state.app.selectedModule,
        selectedLang: state.app.selectedLang,
        menu: state.sidebar.menu,
    }));

    const dispatch = useDispatch();

    const { moduleName } = useParams<{
        moduleName: string | undefined;
        subModuleName: string | undefined;
    }>();

    const history = useHistory();
    const nav = useNavigation();

    const setActiveModule = useCallback(() => {
        if (!moduleName) {
            //Redirect to defaultModule
            nav.replace(`/${defaultModuleName}${history.location.search}`);
            return;
        }

        const moduleNameOrFallback = moduleName;
        const module = modules.find((m) => {
            return m.name.toLowerCase() === moduleNameOrFallback?.toLowerCase();
        });

        appOperations.setSelectedModule(module)(dispatch);
        if (!module) {
            //There should always be a loadded module!
            console.error('Was not able to find a module to load', { moduleName, defaultModuleName, modules });
        }
    }, [defaultModuleName, dispatch, history.location.search, moduleName, modules, nav]);

    useEffect(() => {
        setActiveModule();
    }, [setActiveModule]);

    return (
        <div className={'module-container'}>
            {selectedModule ? <ModuleContainer selectedModuleName={selectedModule?.name} /> : <NoAccessOrDontExist />}
        </div>
    );
};

export default ModuleWrapperFunction;
