import * as React from 'react';
import { FC, SVGProps } from 'react';
const WeeklyCalendarIconSvg: FC<{ fill?: string }> = (props: SVGProps<SVGSVGElement>) => (
    <svg width={18} height={18} fill="none" {...props}>
        <path
            fill={props.fill || '#677074'}
            fillRule="evenodd"
            d="M14.25 3h-.75v-.75a.752.752 0 0 0-.75-.75.752.752 0 0 0-.75.75V3H6v-.75a.752.752 0 0 0-.75-.75.752.752 0 0 0-.75.75V3h-.75c-.833 0-1.493.675-1.493 1.5L2.25 15a1.5 1.5 0 0 0 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V4.5c0-.825-.675-1.5-1.5-1.5Zm0 11.25c0 .412-.338.75-.75.75h-9a.752.752 0 0 1-.75-.75v-7.5h10.5v7.5Zm-7.5-4.5v-1.5h-1.5v1.5h1.5Zm1.5-1.5h1.5v1.5h-1.5v-1.5Zm4.5 1.5v-1.5h-1.5v1.5h1.5Z"
            clipRule="evenodd"
        />
    </svg>
);
export default WeeklyCalendarIconSvg;
