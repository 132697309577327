import { AxiosResponse, AxiosPromise } from 'axios';
import {
    AnonymousUserDataForDynamicQRCode,
    AnonymousUserDataForStaticQRCode,
    Companies,
    CompaniesRequestData,
    Employer,
    RequestParams,
    UsersRequestData,
} from '../common/GuestRegistration/utils/types';
import { CountUsersByAccessAndRolesDto } from './apiTypes/cmsApiTypes';
import {
    UpdateUser,
    Domain,
    Profile,
    Pincode,
    PrivacyPolicyStatus,
    CompanySearchModel,
    WifiAtWorkPassword,
    CompanyBasicInfo,
    CompanyContactPerson,
    TenancyInfo,
    UpdateCompanyBasicInfo,
    UpdateTenancyInfo,
    PolicyType,
    CompanyAdmin,
    CreateNewTenantDTO,
    CreateMultipleUsersDto,
    Role,
    VehicleModel,
    AppContext,
    RolePhrase,
    Users,
    OwnUser,
    GenerateQRCodeData,
} from './apiTypes/portalApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const portalApiUrl = process.env.REACT_APP_APIM_PORTAL_URL;
type HttpMethod = 'get' | 'post' | 'delete';
export function getUserProfile(moduleId: number, userId: number): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/modules/${moduleId}/users/${userId}`;
    return http.get(url);
}

export function getCompanies(): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/nb/portals/${getPortalId()}/companies`;
    return http.get(url);
}

export function getUsersForCompany(portalId: string, companyId: string): Promise<AxiosResponse<{ items: Users[] }>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalId}/companies/${companyId}/users`;
    return http.get(url);
}

export function getUsersAccessibleRoles(): Promise<AxiosResponse<Role[]>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/adm/portals/${getPortalId()}/roles/list`;
    return http.get(url);
}

export function getUsersForCompanyWithoutLoadingInfo(companyId: string): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/users`;
    return http.get(url);
}

export function getUserForCompanyInfo(companyId: string, userId: string): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/users/${userId}`;
    return http.get(url);
}

export function getCompanyAdmins(companyId: string): Promise<AxiosResponse<CompanyAdmin[]>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/admins`;
    return http.get<CompanyAdmin[]>(url, { params: { companyId } });
}

export function getIsEmailUnique(email: string): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/email`;
    return http.get(url, { params: { email } });
}

export function updateUserForCompanyInfo(companyId: string, userId: string, data: UpdateUser): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/users/${userId}`;
    return http.patch(url, data);
}

export function createUserForCompany(companyId: string, email: string): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/users`;
    return http.put<string, AxiosResponse<never>>(url, {
        contactInfo: [
            {
                name: 'E-mail',
                value: email,
            },
        ],
    });
}

export function getDetailsForCompany(companyId: string): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/domains`;
    return http.get(url);
}

export function updateDomainForCompanyFromApi(companyId: string, domain: Domain): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/domains`;
    return http.put<Domain, AxiosResponse<never>>(url, domain);
}

export function deleteDomainFromCompany(companyId: string, domain: string): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/domains/${domain}`;
    return http.delete(url);
}

export function deleteUserFromCompany(
    portalId: string | number,
    companyId: string,
    userId: string,
): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalId}/companies/${companyId}/users/${userId}`;
    return http.delete(url);
}

export function restoreUserFromCompany(
    portalId: string | number,
    companyId: string,
    userId: string,
): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalId}/companies/${companyId}/users/${userId}`;
    return http.post(url);
}

export function getProfile(): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/users/profile`;
    return http.get(url);
}

export function updateProfile(profileData: Profile): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/users/profile`;
    return http.patch(url, profileData);
}

export function sendPinCodeSmsToUser(userId: string, portalId: string): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalId}/users/${userId}/pincode`;
    return http.post(url);
}

export function updatePinCode(pincode: Pincode): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/pincode`;
    return http.put(url, pincode);
}

export function deleteSelf(): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/users`;
    return http.delete(url);
}

export function uploadAvatar(image: FormData): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/avatar`;
    return http.put(url, image);
}

export function getPrivacyPolicyStatus(policyType = PolicyType.Portal): Promise<AxiosResponse<PrivacyPolicyStatus>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/policy?policyType=${policyType}`;
    return http.get(url);
}

export function setPrivacyPolicyUserStatus(
    version: string,
    authenticatedUser: string,
    status: 'Approved' | 'Read',
): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/policy/${version}/users/${authenticatedUser}/status/${status}`;
    return http.post(url);
}

export function updateUser(userData: UpdateUser): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/users/profile`;
    return http.put(url, userData);
}

export function confirmCurrentUser(): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/confirm`;
    return http.patch(url);
}

export function getCompanyBasicInfo(companyId: string): Promise<AxiosResponse<CompanyBasicInfo>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/basic-info`;
    return http.get(url);
}

export const updateCompanyBasicInfo = (companyGuid: string, data: UpdateCompanyBasicInfo): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyGuid}/basic-info`;
    return http.patch(url, data);
};

export const restoreSelf = (): Promise<void> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/users/restore`;
    return http.post(url);
};

export const getCompanyTenancyInfo = (companyGuid: string): Promise<AxiosResponse<TenancyInfo>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyGuid}/tenancy`;
    return http.get(url);
};

export const updateCompanyTenancyInfo = (companyGuid: string, data: UpdateTenancyInfo): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyGuid}/tenancy`;
    return http.put(url, data);
};

export function getCompanySearchInfo(
    countUsers: boolean,
    excludeInactive: boolean,
): Promise<AxiosResponse<CompanySearchModel[]>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/search`;

    return http.get(url, {
        params: {
            countUsers,
            excludeInactive,
        },
    });
}

export function changeWifiAtWorkPassword(data: WifiAtWorkPassword): Promise<AxiosResponse<string>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/wifipassword`;

    return http.put<{ password: string }, AxiosResponse<string>>(url, data, {
        params: { portalId: getPortalId() },
    });
}

export const postponeSetWifiAtWorkPassword = (companyGuid: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyGuid}/users/postponePasswordChange`;
    return http.post(url);
};

export const getCompanyContactPerson = (companyGuid: string): Promise<AxiosResponse<CompanyContactPerson>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyGuid}/contact`;
    return http.get(url);
};

export const createNewCompany = (company: UpdateCompanyBasicInfo): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies`;
    return http.post(url, company);
};

export const createNewCompanyV2 = (company: CreateNewTenantDTO): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/company`;
    return http.post(url, company);
};

export const createMultipleUsers = (
    users: CreateMultipleUsersDto,
    companyGuid: string,
): Promise<AxiosResponse<number>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyGuid}/usersBulk`;
    return http.put<number>(url, users);
};

export const countUsersByAccessEntitiesAndRoles = (
    dto: CountUsersByAccessAndRolesDto,
): Promise<AxiosResponse<number>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/articleAccessCount`;
    return http.put<number>(url, dto);
};

export const getCompaniesListForStaticQRCode = (
    data: UsersRequestData,
    filterParams: RequestParams,
): Promise<AxiosResponse<Companies[]>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/companies`;
    return http.post(url, data.key, {
        params: filterParams,
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const getCompaniesListForDynamicQRCode = (
    data: CompaniesRequestData,
    filterParams: RequestParams,
): Promise<AxiosResponse<Companies[]>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/companies/new`;
    return http.post(url, data, {
        params: filterParams,
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const getCompanyUsersListForStaticQRCode = (
    data: UsersRequestData,
    filterParams: RequestParams,
): Promise<AxiosResponse<Employer[]>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/companies/${data.companyKey}/users`;
    return http.post(url, data.key, {
        params: filterParams,
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const getCompanyUsersListForDynamicQRCode = (
    data: UsersRequestData,
    filterParams: RequestParams,
): Promise<AxiosResponse<Employer[]>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/companies/${
        data.companyKey
    }/users/new`;
    return http.post(url, data, {
        params: filterParams,
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const postRegisterAnonymousUserForDynamicQRCode = (
    data: AnonymousUserDataForDynamicQRCode,
): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/register/new`;
    return http.post(url, JSON.stringify(data), {
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const postRegisterAnonymousUserFromStaticQRCode = (
    data: AnonymousUserDataForStaticQRCode,
): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/register`;
    return http.post(url, JSON.stringify(data), {
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const generateQRCode = ({
    location,
    guestRegistrationKey,
    expiresAfter,
}: GenerateQRCodeData): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/qr/new`;
    return http.post(
        url,
        { location, guestRegistrationKey, expiresAfter: expiresAfter },
        {
            withCredentials: false,
            headers: { 'Content-Type': 'application/json' },
        },
    );
};

export const generateLink = ({
    location,
    guestRegistrationKey,
    expiresAfter,
}: GenerateQRCodeData): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/link`;

    return http.post(
        url,
        { location, guestRegistrationKey, expiresAfter: expiresAfter },
        {
            withCredentials: false,
            headers: { 'Content-Type': 'application/json' },
        },
    );
};

export const createVehicle = (vehicleDto: VehicleModel): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/vehicle`;
    return http.post(url, vehicleDto);
};

export const updateVehicle = (vehicleDto: VehicleModel, vehicleId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/vehicle/${vehicleId}`;
    return http.put(url, vehicleDto);
};

export const deleteVehicle = (vehicleId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/vehicle/${vehicleId}`;
    return http.delete(url);
};

export const updateCompanyVisitationStatus = (value: boolean, companyId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/companies/${companyId}/visitation/toggle`;
    return http.put<{ isEnabled: boolean }, AxiosResponse<never>>(
        url,
        {},
        {
            params: { isEnabled: value },
        },
    );
};

export const updateUserVisitationStatus = (value: boolean, userId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/${userId}/visitation/toggle`;
    return http.put<{ isEnabled: boolean }, AxiosResponse<never>>(
        url,
        {},
        {
            params: { isEnabled: value },
        },
    );
};

export const updateUserThemselvesVisitationStatus = (value: boolean): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/users/visitation/toggle`;
    return http.put<{ isEnabled: boolean }, AxiosResponse<never>>(
        url,
        {},
        {
            params: { isEnabled: value },
        },
    );
};

export const setDefaultPortal = (portalId: number): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalId}/setDefault`;
    return http.post(url);
};

export const setSelectedPortal = (portalId: number): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalId}/setSelected`;
    return http.post(url);
};

export function getAppContext(): Promise<AxiosResponse<AppContext>> {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/appcontext`;
    return http.get<AppContext>(url);
}

export const getPortalRoles = (): AxiosPromise<RolePhrase[]> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/adm/portals/${getPortalId()}/roles/list`;
    return http.get<RolePhrase[]>(url);
};

export const getOwnUserInfo = (portalId: string): AxiosPromise<OwnUser> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalId}/user-info`;
    return http.get(url);
};

const guestRegistrationOperation = (method: HttpMethod): (() => AxiosPromise<string>) => {
    return () => {
        const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/guestregistrationkey`;
        return http[method](url);
    };
};

export const getGuestRegistrationKey = guestRegistrationOperation('get');
export const createGuestRegistrationKey = guestRegistrationOperation('post');
export const deleteGuestRegistrationKey = guestRegistrationOperation('delete');

const physicalAccessApiUrl = process.env.REACT_APP_ACCESS_CONTROL_URL;

const openDoorKey = 'd615b8ed-3650-4d9a-8010-20a483f4394c';

export const pulseOpenDoor = async (): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${physicalAccessApiUrl}/${getPortalId()}/system/locks/public/open`;
    return http.patch(
        url,
        { openDoorKey },
        { withCredentials: false, headers: { 'Content-Type': 'application/json' } },
    );
};

export const postRegisterNotPortalGuest = (userName: string): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/portals/${getPortalId()}/guest/register/new`;
    return http.post(url, JSON.stringify(userName), {
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
    });
};
