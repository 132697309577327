import { Key, ReactElement } from 'react';
import styled from 'styled-components';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

const StyledTable = styled.table`
    position: relative;
    border-spacing: 0 1rem;
    border-collapse: separate;
    padding: 0 0.5rem;
    width: 100%;
`;

const NoResultContainer = styled.div`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoResultMessageText = styled.p`
    font-weight: 900;
    font-size: 1.429rem;
    line-height: 2.286rem;
    color: var(--text-placeholder-color);
`;

export enum OrderEnum {
    asc = 'asc',
    desc = 'desc',
}

export type ColumnType = string | number;

interface TableProps {
    columns: {
        name: string;
        key: Key;
        label: string | JSX.Element;
        path?: ColumnType;
        width?: number | string;
        content?(item: unknown): JSX.Element | null;
        headerOnly?: boolean;
        bodyOnly?: boolean;
    }[];
    sortColumn?: { path: ColumnType; order: unknown };
    onSort?(sortColumn: { path: ColumnType; order: OrderEnum }): void;
    onRowClick(rowId: string): void;
    data: { id: string }[] & unknown;
    isLoading: boolean;
    collapse?: boolean;
    showHighlight?: boolean;
    noResultMessage?: string;
    className?: string;
}

const Table = ({
    columns,
    sortColumn,
    onSort,
    data,
    onRowClick,
    isLoading,
    collapse,
    noResultMessage,
    className,
    showHighlight,
}: TableProps): ReactElement => {
    return (
        <>
            <StyledTable className={className}>
                {!!onSort && !!sortColumn && (
                    <TableHeader
                        columns={columns.filter((column) => !column.bodyOnly)}
                        sortColumn={sortColumn}
                        onSort={onSort}
                        disabled={isLoading}
                        collapse={collapse}
                    />
                )}
                <TableBody
                    columns={columns.filter((column) => !column.headerOnly)}
                    data={data}
                    onRowClick={onRowClick}
                    collapse={collapse}
                    showHighlight={showHighlight}
                />
            </StyledTable>
            {noResultMessage && data.length === 0 && !isLoading ? (
                <NoResultContainer>
                    <NoResultMessageText>{noResultMessage}</NoResultMessageText>
                </NoResultContainer>
            ) : null}
        </>
    );
};

export default Table;
