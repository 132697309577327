import { ReactElement, forwardRef, useState, useMemo, SyntheticEvent, useCallback, ForwardedRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionMeta, CSSObjectWithLabel, IndicatorsContainerProps } from 'react-select';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { useIsMobile } from '../../utility/hooks/useIsMobile';
import { SvgIcon } from '../index';
import { GroupedOptionType, LayoutPosition, OptionType } from './searchSelect/types';

interface ArrowContainerType {
    isOpen: boolean;
    isGroupHeadingArrow?: boolean;
}

interface StyledCheckMarkWrapperType {
    isSelected: boolean;
    isGroupOption?: boolean;
}

interface OptionTextProps {
    isGroupExpanded?: boolean;
    isActive: boolean;
}

const IconContainer = styled.div`
    display: flex;
    padding-right: 0.5rem;

    svg {
        path {
            fill: #475156;
        }
    }
`;

const OptionText = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ isGroupExpanded }: OptionTextProps) => (isGroupExpanded ? 'var(--primary-color)' : 'inherit')};
    font-weight: ${({ isActive }: OptionTextProps) => (isActive ? '700' : '400')};
`;

const StyledCheckMarkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.714rem;
    min-height: 1.714rem;
    border-radius: 0.357rem;
    border: 2px solid var(--primary-color);
    background-color: ${({ isSelected }: StyledCheckMarkWrapperType) => (isSelected ? 'var(--primary-color)' : '#fff')};
    margin-right: 1.143rem;
    position: relative;

    &::after {
        content: '';
        width: ${({ isGroupOption }: StyledCheckMarkWrapperType) => (isGroupOption ? '1px' : '0')};
        height: 2.857rem;
        position: absolute;
        background-color: ${({ isSelected }: StyledCheckMarkWrapperType) =>
            isSelected ? 'var(--primary-color)' : '#D4D6D7'};
        right: -0.7rem;
    }

    svg {
        display: inline-flex;
        width: 1.286rem;
        height: 1.286rem;

        path {
            fill: var(--primary-on-color);
        }
    }
`;

const StyledPartlySelectedMark = styled.div`
    width: 0.857rem;
    height: 0.857rem;
    background-color: var(--primary-color);
`;

const ArrowButtonIcon = styled.div`
    display: inline-flex;
    margin-left: ${({ isGroupHeadingArrow }: ArrowContainerType) => (isGroupHeadingArrow ? 'auto' : '0')};
    padding-left: ${({ isGroupHeadingArrow }: ArrowContainerType) => (isGroupHeadingArrow ? '0.357rem' : '0')};

    svg {
        width: 0.857rem;
        height: 0.857rem;
        transform: ${({ isOpen }: ArrowContainerType) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};

        path {
            fill: var(--primary-color);
        }
    }
`;

const GroupHeading = styled.div`
    background-color: var(--primary-on-color-light);
    color: #475156;
    cursor: pointer;
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding: 0.571rem 1.143rem;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--background-color);
    }

    :focus-visible,
    :focus {
        outline: 0;
        background-color: var(--background-color);
    }
`;

const Wrapper = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    gap: 0.286rem;
    position: relative;
    width: ${({ width }: { width?: string }) => width ?? 'auto'};
`;

type LabelProps = { isError: boolean };
const Label = styled.p`
    margin: 0;
    font-size: 0.857rem;
    color: ${({ isError }: LabelProps) => (isError ? 'var(--error-color)' : 'var(--text-placeholder-color)')};
`;
const selectAllValue = '<SELECT_ALL>';

const StyledButton = styled.button`
    background: transparent;
    border: none;
`;
const customStyles = {
    option: (provided: CSSObjectWithLabel, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'var(--background-color)' : '#fff',
        color: '#475156',
        cursor: 'pointer',
        fontSize: '1.143rem',
        lineHeight: '1.714rem',
        padding: '0.571rem 1.143rem',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: !state.selectProps.isGroupedOption || state.value === selectAllValue ? '1.143rem' : '2.857rem',

        '&:hover': {
            backgroundColor: 'var(--background-color)',
        },
    }),
    groupHeading: (provided: CSSObjectWithLabel, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'var(--background-color)' : 'var(--primary-on-color-light)',
        color: '#475156 !important',
        cursor: 'pointer',
        fontSize: '1.143rem',
        lineHeight: '1.714rem',
        padding: '0.571rem 1.143rem',
        display: 'flex',
        alignItems: 'center',

        '&:hover': {
            backgroundColor: 'var(--background-color)',
        },
    }),
    group: (provided: CSSObjectWithLabel, state: any) => ({
        padding: 0,
        '& div:nth-child(2)': {
            display: state.selectProps.collapsedGroups.includes(state.headingProps.id) ? 'none' : '',
        },
    }),
    control: (provided: CSSObjectWithLabel, state: any) => ({
        width: 'auto',
        minWidth: '20.714rem',
        marginBottom: state.selectProps.isSearchable ? '0.714rem' : 0,
        border: '1px solid #D4D6D7',
        borderRadius: '0.357rem',
        cursor: 'text',
        display: 'flex',
        outline: state.isFocused && state.selectProps.isSearchable ? '1px solid var(--primary-color)' : 'none',
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        position: state.selectProps.positionMenu,
        margin: 0,
        borderRadius: 0,
        left: 0,
        zIndex: 10000,
    }),
    menuList: (provided: CSSObjectWithLabel, state: any) => ({
        ...provided,
        margin: 0,
        maxHeight: state.selectProps.height
            ? state.selectProps.height
            : state.selectProps.isMobile
            ? '14.6rem'
            : '23.4rem',
        width: '100%',
        padding: 0,
    }),
    menuPortal: ({ left, top, ...provided }: CSSObjectWithLabel) => ({
        ...provided,
        zIndex: 1,
        left: 'auto',
        top: 'auto',
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        minHeight: '3rem',
        display: 'flex',
        overflow: 'hidden',
        flexWrap: state.selectProps.isCommonSelect ? 'wrap' : 'nowrap',
        background: 'var(--primary-on-color)',
        borderRadius: '0.357rem',
    }),
    container: (provided: CSSObjectWithLabel, state: any) => ({
        ...provided,
        padding: state.selectProps.isSearchable ? '0.571rem 0.536rem' : 0,
        boxShadow: state.selectProps.isCommonSelect ? 'none' : '0px -1px 10px rgba(0, 0, 0, 0.15)',
        width: '100%',
    }),
    input: (provided: CSSObjectWithLabel) => ({
        ...provided,
        input: {
            opacity: '1 !important',
        },
    }),
    multiValue: (provided: CSSObjectWithLabel) => ({
        ...provided,
        backgroundColor: '#E9EBEB',
        borderRadius: '2.143rem',
        padding: '0.286rem 0.571rem',
        display: 'flex',
        alignItems: 'center',
    }),
    multiValueLabel: (provided: CSSObjectWithLabel, state: any) => ({
        ...provided,
        color: state.data.isFixed || state.selectProps.isDisabled ? '#A8ADAF' : '#475156',
    }),
    multiValueRemove: (provided: CSSObjectWithLabel, state: any) => ({
        display: state.data.isFixed ? 'none' : 'inline-flex',
        cursor: 'pointer',
        color: state.selectProps.isDisabled && '#A8ADAF',
    }),
};

const CustomValueContainer = ({ children, ...props }: any): ReactElement => {
    return (
        <components.ValueContainer {...props}>
            <IconContainer>
                <SvgIcon name="Search" />
            </IconContainer>
            {children}
        </components.ValueContainer>
    );
};

const Option = (props: any): JSX.Element | null => {
    if (props.data.isFixed) return null;
    return (
        <components.Option {...props}>
            {!props.selectProps.hideCheckboxes && (
                <StyledCheckMarkWrapper
                    isGroupOption={props.selectProps.isGroupedOption && props.value !== selectAllValue}
                    isSelected={props.isSelected}
                >
                    {props.isSelected ? <SvgIcon name="CheckIcon" /> : null}
                </StyledCheckMarkWrapper>
            )}
            <OptionText isActive={props.isSelected}>{props.label}</OptionText>
        </components.Option>
    );
};
enum SelectActions {
    SelectOption = 'select-option',
    DeselectOption = 'deselect-option',
    RemoveValue = 'remove-value',
}

type GroupedOptionActionType = SelectActions.DeselectOption | SelectActions.SelectOption;

const flatGroupedOptions = (optionsArray: Array<GroupedOptionType | OptionType>) => {
    return optionsArray.map((item) => ('options' in item ? item.options : item)).flat();
};

const CustomGroupHeading = (props: any) => {
    const [isGroupOpen, setIsGroupOpen] = useState(true);
    const {
        value,
        onChange,
        options,
        handleGroupCollapse,
    }: {
        value: OptionType[];
        onChange: (options: OptionType[] | [], action?: { action: GroupedOptionActionType }) => void;
        options: GroupedOptionType[];
        handleGroupCollapse: (id: string) => void;
    } = props.selectProps;

    const memoizedOptions = useMemo(
        () => options.filter((option) => (option as unknown as OptionType).value !== selectAllValue),
        [JSON.stringify(options)],
    );

    const filteredValue = useMemo(() => {
        const isAllValuesSelected = value.length === 1 && value[0].value === selectAllValue;

        if (isAllValuesSelected) return flatGroupedOptions(memoizedOptions);
        return value;
    }, [value]);

    const selectedGroupOptionValues = useMemo(
        () => memoizedOptions.find(({ label }) => label === props.data.label)?.options.map(({ value }) => value),
        [options],
    );

    const isOneOfGroupItemsSelected = useMemo(
        () => !!filteredValue?.find((filterOption) => selectedGroupOptionValues?.includes(filterOption.value)),
        [selectedGroupOptionValues, value],
    );

    const isAllGroupItemsSelected = useMemo(() => {
        return (
            filteredValue
                .filter((filterOption) => selectedGroupOptionValues?.includes(filterOption.value))
                .map(({ value }) => value)
                .sort()
                .join('') === selectedGroupOptionValues?.sort().join('')
        );
    }, [selectedGroupOptionValues, value]);

    const getSelectionMark = () => {
        if (isAllGroupItemsSelected) return <SvgIcon name="CheckIcon" />;
        if (isOneOfGroupItemsSelected) return <StyledPartlySelectedMark />;
        return null;
    };

    const handleGroupHeadingClick = () => {
        if (isOneOfGroupItemsSelected) {
            onChange(
                filteredValue.filter((option: OptionType) => !selectedGroupOptionValues?.includes(option.value)),
                {
                    action: SelectActions.DeselectOption,
                },
            );
        } else {
            const selectedGroupOptions =
                memoizedOptions?.find(({ label }) => label === props.data.label)?.options || [];
            onChange([...filteredValue, ...selectedGroupOptions], { action: SelectActions.SelectOption });
        }
    };

    const handleGroupHeaderCollapseClick = (evt: SyntheticEvent) => {
        evt.stopPropagation();
        setIsGroupOpen((p) => !p);
        handleGroupCollapse(props.id);
    };

    return (
        <GroupHeading id={props.id} onClick={handleGroupHeadingClick}>
            <StyledCheckMarkWrapper isSelected={isAllGroupItemsSelected}>{getSelectionMark()}</StyledCheckMarkWrapper>
            <OptionText isActive={isGroupOpen} isGroupExpanded={isGroupOpen}>
                {props.data.label}
            </OptionText>
            <ArrowButtonIcon isGroupHeadingArrow isOpen={isGroupOpen} onClick={handleGroupHeaderCollapseClick}>
                <SvgIcon name="ArrowPrimary" alt="arrow" />
            </ArrowButtonIcon>
        </GroupHeading>
    );
};

type ActionMetaOptionType = ActionMeta<{ value: string; label: string }>;

interface StyledSelectProps {
    name: string;
    options: OptionType[] | GroupedOptionType[];
    onChange: (options: OptionType[] | OptionType, actionMeta?: ActionMetaOptionType) => void;
    value: OptionType[] | OptionType;
    positionMenu?: LayoutPosition;
    hideSelectedOptions?: boolean;
    isLoading?: boolean;
    defaultMenuIsOpen?: boolean;
    isSearchable?: boolean;
    placeholder?: string;
    menuIsOpen?: boolean;
    closeMenuOnSelect?: boolean;
    controlShouldRenderValue?: boolean;
    backspaceRemovesValue?: boolean;
    noOptionsMessage?: string;
    searchValue?: string;
    setSearchValue?: (searchValue: string) => void;
    isMulti?: boolean;
    isCommonSelect?: boolean;
    label?: string;
    disabled?: boolean;
    error?: string;
    allSelectable?: boolean;
    height?: string;
    menuPositionIsFixed?: boolean;
    toggleMenu?: () => void;
    isDropdownControl?: boolean;
    hideCheckboxes?: boolean;
    width?: string;
    required?: boolean;
}

const StyledSelect = forwardRef(
    (
        {
            name,
            isLoading,
            defaultMenuIsOpen,
            isSearchable = false,
            placeholder,
            menuIsOpen,
            closeMenuOnSelect,
            controlShouldRenderValue,
            backspaceRemovesValue,
            noOptionsMessage,
            options,
            onChange,
            value,
            searchValue,
            setSearchValue,
            hideSelectedOptions,
            isMulti,
            positionMenu = LayoutPosition.Absolute,
            isCommonSelect,
            label,
            disabled,
            error,
            allSelectable = false,
            menuPositionIsFixed = false,
            height,
            toggleMenu,
            isDropdownControl,
            hideCheckboxes = false,
            width,
            required,
        }: StyledSelectProps,
        ref,
    ): ReactElement => {
        const { t: tCommon } = useTranslation('common');
        const isMobile = useIsMobile();
        const memoizedOptions = useMemo(() => options, [JSON.stringify(options)]);
        const flatMemoizedOptions = useMemo(() => {
            return flatGroupedOptions(memoizedOptions);
        }, [memoizedOptions]);
        const isGroupedOption = options[0] && Object.keys(options[0]).includes('options');
        const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);
        const handleGroupCollapse = useCallback((id: string) => {
            setCollapsedGroups((prevCollapsedGroups) =>
                prevCollapsedGroups.includes(id)
                    ? prevCollapsedGroups.filter((groupId) => groupId !== id)
                    : [...prevCollapsedGroups, id],
            );
        }, []);

        const filterOption = ({ label }: OptionType, searchValue: string) => {
            const groupOptions = options as GroupedOptionType[];
            if (label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) return true;

            const searchedGroups = groupOptions.filter((group) =>
                group.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
            );

            if (searchedGroups.length) {
                for (const searchedGroup of searchedGroups) {
                    return !!searchedGroup.options.find(
                        (opt: OptionType) => opt.label.toLocaleLowerCase() === label.toLocaleLowerCase(),
                    );
                }
            }
            return false;
        };

        const onInputChange = (text: string, { action }: { action: string }) => {
            action === 'input-change' && setSearchValue && setSearchValue(text);
        };
        //for control open/close dropdown
        const IndicatorsContainer = (props: IndicatorsContainerProps<any>) => {
            return (
                <div>
                    <StyledButton type="button" onClick={toggleMenu}>
                        <components.IndicatorsContainer {...props} />
                    </StyledButton>
                </div>
            );
        };
        const selectComponentsOverrides = isCommonSelect
            ? isSearchable
                ? { ValueContainer: CustomValueContainer }
                : {}
            : {
                  ValueContainer: isSearchable ? CustomValueContainer : () => null,
                  DropdownIndicator: () => null,
                  ClearIndicator: () => null,
                  IndicatorSeparator: () => null,
              };

        const selectAllOption = {
            value: selectAllValue,
            label: tCommon('all'),
        };

        const valueRef = useRef(value);
        valueRef.current = value;
        const currentRef = valueRef.current as OptionType[];

        const isSelectAllSelected = currentRef.length === flatMemoizedOptions.length;

        const isOptionSelected = (option: OptionType) =>
            currentRef.some(({ value }) => value === option.value) || isSelectAllSelected;

        const optionsIncludedSelectAll = [selectAllOption, ...memoizedOptions];

        const currentValue = isSelectAllSelected ? [selectAllOption] : value;

        const handleChange = (newValue: OptionType[], eventActionMeta: ActionMetaOptionType) => {
            if (allSelectable) {
                const { action, option, removedValue } = eventActionMeta;
                // if action is selection and option is select all, then select all options
                if (action === SelectActions.SelectOption && option?.value === selectAllOption.value) {
                    onChange(flatMemoizedOptions);
                } else if (
                    // if action is deselection of select all option or removal all of the values,
                    // then select [] as list of options
                    (action === SelectActions.DeselectOption && option?.value === selectAllOption.value) ||
                    (action === SelectActions.RemoveValue && removedValue.value === selectAllOption.value)
                ) {
                    onChange([], eventActionMeta);
                    // if action is deselection and select all options was selected before,
                    // then remove deselected option and select all option as well
                } else if (action === SelectActions.DeselectOption && isSelectAllSelected) {
                    onChange(
                        (options as []).filter(({ value }) => value !== option?.value),
                        eventActionMeta,
                    );
                } else {
                    onChange(newValue || []);
                }
            } else {
                onChange(newValue as unknown as OptionType[]);
            }
        };

        return (
            <Wrapper width={width ?? 'auto'}>
                {label && <Label isError={!!error}>{label + (required ? '*' : '')}</Label>}
                <Select
                    ref={ref as ForwardedRef<any>}
                    required={required}
                    tabSelectsValue={false}
                    isLoading={isLoading}
                    name={name}
                    defaultMenuIsOpen={defaultMenuIsOpen}
                    styles={customStyles}
                    filterOption={isGroupedOption ? filterOption : undefined}
                    {...{
                        isMobile,
                        handleGroupCollapse,
                        isGroupedOption,
                        collapsedGroups,
                        positionMenu,
                        isCommonSelect,
                        height,
                        hideCheckboxes,
                    }}
                    components={{
                        GroupHeading: CustomGroupHeading,
                        Option,
                        IndicatorsContainer: isDropdownControl ? IndicatorsContainer : () => null,
                        ...selectComponentsOverrides,
                    }}
                    onChange={(newValue, actionMeta: ActionMetaOptionType) =>
                        handleChange(newValue as unknown as OptionType[], actionMeta)
                    }
                    isClearable={isMulti && Array.isArray(value) && !value.some(({ isFixed }) => isFixed)}
                    placeholder={placeholder}
                    menuIsOpen={menuIsOpen}
                    {...(allSelectable && { isOptionSelected: isOptionSelected })}
                    closeMenuOnSelect={closeMenuOnSelect}
                    isMulti={isMulti}
                    options={allSelectable ? optionsIncludedSelectAll : memoizedOptions}
                    hideSelectedOptions={hideSelectedOptions}
                    isSearchable={isSearchable}
                    inputValue={searchValue}
                    onInputChange={onInputChange}
                    controlShouldRenderValue={controlShouldRenderValue}
                    backspaceRemovesValue={backspaceRemovesValue}
                    value={allSelectable ? currentValue : value}
                    noOptionsMessage={() => noOptionsMessage}
                    isDisabled={disabled}
                    menuPosition={menuPositionIsFixed ? 'fixed' : 'absolute'}
                />
                {error && <Label isError={!!error}>{error}</Label>}
            </Wrapper>
        );
    },
);

StyledSelect.displayName = 'StyledSelect';

export default StyledSelect;
