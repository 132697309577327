import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarOperations } from '../../../common/Sidebar/duck';
import { useNavigation } from '../../../utility';
import { useIsMobile } from '../../../utility/hooks/useIsMobile';

const useUsersAdminNavigation = (): {
    usersLink: string;
    preferencesLink: string;
    goToUsersLink(): void;
    goToPreferencesLink(): void;
} => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    return useMemo(() => {
        const usersLink = '/admin/companySettings/users';
        const preferencesLink = '/admin/companySettings/preferences';
        const onNavigate = (url: string, shouldCloseSideBar = true) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url);
        };

        return {
            usersLink,
            preferencesLink,
            goToUsersLink: () => onNavigate(usersLink),
            goToPreferencesLink: () => onNavigate(preferencesLink),
        };
    }, [navigation]);
};

export default useUsersAdminNavigation;
