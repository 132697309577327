import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import KitchenAdminDynamicMenuItem from '../../../modules/Admin/KitchenAdmin/KitchenAdminDynamicMenuItem';
import UsersAdminDynamicMenuItem from '../../../modules/Admin/UsersAdmin/UsersAdminDynamicMenuItem';
import { useUserHasPerm } from '../../../utility';
import Perm from '../../perms';
import BottomMenuItem from './BottomMenuItem';

const BottomMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #f2f2f2;
`;

interface BottomMenuProps {
    onClick(moduleName: string, subModuleName: string): void;
}

const BottomMenu = ({ onClick }: BottomMenuProps): ReactElement => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const userIsCompanyAdmin = useUserHasPerm(Perm.CompanyAdmin);
    const userIsKitchenWorker = useUserHasPerm(Perm.IntegrationSettingUpdate);
    const { t } = useTranslation('Admin');
    return (
        <BottomMenuWrapper>
            {userIsKitchenWorker ? <KitchenAdminDynamicMenuItem /> : null}
            {userIsPortalAdmin || userIsCompanyAdmin ? <UsersAdminDynamicMenuItem /> : null}
            {userIsPortalAdmin ? (
                <BottomMenuItem
                    module="admin"
                    subModule="tenants"
                    icon="GearsIcon"
                    text={t('tenantsAdmin.portalSettingSideBarMenuItem')}
                    onClick={onClick}
                />
            ) : null}
        </BottomMenuWrapper>
    );
};

export default BottomMenu;
