export enum CalendarType {
    DAILY_PICKER = 'DAILY_PICKER',
    WEEKLY_PICKER = 'WEEKLY_PICKER',
}
export interface TableTimeControllerProps {
    showLabel: boolean | undefined;
    calendarType?: CalendarType;
}

export const START_WORKING_TIME = '08:00';
export const END_WORKING_TIME = '18:00';
