import { createContext, createRef, Dispatch, ReactElement, RefObject, useReducer, useRef } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    OrderSortDirection,
    OrderSortingType,
    InventorySortDirection,
    InventorySortingType,
} from '../../api/apiTypes/shopApiTypes';
import { Perm } from '../../common';
import { OptionType } from '../../components/select/searchSelect/types';
import { useUserHasPerm } from '../../utility';
import CompanyPurchases from './CompanyPurchases/CompanyPurchases';
import Inventory from './Inventory/Inventory';
import MyPurchases from './MyPurchases/MyPurchases';
import PortalPurchases from './PortalPurchases/PortalPurchases';
import ShopSettings from './Settings/ShopAtWorkSettings';
import useShopAtWorkNavigation from './useShopAtWorkNavigation';

const Card = styled.div`
    border-radius: 0.357rem;
    margin: 0.571rem;
    overflow-y: auto;
    background: var(--surface-color-light);
    width: 100%;
    padding: 1.143rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export enum ShopOverviewView {
    MyPurchases = 'MyPurchases',
    CompanyPurchases = 'CompanyPurchases',
    PortalPurchases = 'PortalPurchases',
    Inventory = 'Inventory',
}

export enum ShopOverviewHeader {
    MyPurchases = 'Mine transaksjoner',
    CompanyPurchases = 'Våre transaksjoner',
    PortalPurchases = 'Portal transaksjoner',
    Inventory = 'Produktkatalog',
}

type FromOrderId = { orderId: string | null; previousView: ShopOverviewView | null };
type UpdateFromOrderId = { type: 'UPDATE_FROM_ORDER_ID'; payload: FromOrderId };

type UpdateShopOverviewView = {
    type: 'UPDATE_SHOP_OVERVIEW_VIEW';
    payload: ShopOverviewView;
};

type UpdateShopOverviewFilters = {
    type: 'UPDATE_SHOP_OVERVIEW_FILTERS';
    payload: { [key: string]: OptionType[] | [] | ShopOverviewView };
};

type UpdateShopOverviewSearchTerm = {
    type: 'UPDATE_SHOP_OVERVIEW_SEARCH_TERM';
    payload: string;
};

type UpdateShopOverviewSortingType = {
    type: 'UPDATE_SHOP_OVERVIEW_SORTING_TYPE';
    payload: { sortingType: OrderSortingType; view: ShopOverviewView };
};
type UpdateShopOverviewSortingDirection = {
    type: 'UPDATE_SHOP_OVERVIEW_SORTING_DIRECTION';
    payload: { sortingDirection: OrderSortDirection; view: ShopOverviewView };
};

type UpdateInventoryOverviewSortingType = {
    type: 'UPDATE_INVENTORY_OVERVIEW_SORTING_TYPE';
    payload: { sortingType: InventorySortingType; view: ShopOverviewView };
};
type UpdateInventoryOverviewSortingDirection = {
    type: 'UPDATE_INVENTORY_OVERVIEW_SORTING_DIRECTION';
    payload: { sortingDirection: InventorySortDirection; view: ShopOverviewView };
};

type UpdateInventoryOverviewFilters = {
    type: 'UPDATE_INVENTORY_OVERVIEW_FILTERS';
    payload: { [key: string]: OptionType[] | [] | ShopOverviewView };
};

type ShopAtWorkAction =
    | UpdateShopOverviewView
    | UpdateShopOverviewFilters
    | UpdateShopOverviewSortingType
    | UpdateShopOverviewSortingDirection
    | UpdateShopOverviewSearchTerm
    | UpdateFromOrderId
    | UpdateInventoryOverviewSortingType
    | UpdateInventoryOverviewSortingDirection
    | UpdateInventoryOverviewFilters;

export interface ShopAtWorkPurchaseFilters {
    sortingType: OrderSortingType;
    sortingDirection: OrderSortDirection;
    statuses: OptionType[] | [];
    payments: OptionType[] | [];
    employees: OptionType[] | [];
    companies: OptionType[] | [];
}

export interface ShopAtWorkInventoryFilters {
    sortingType: InventorySortingType;
    sortingDirection: InventorySortDirection;
    statuses: OptionType[] | [];
    types: OptionType[] | [];
}

export interface ShopAtWorkState {
    shopOverviewView: ShopOverviewView | null;
    myOrders: ShopAtWorkPurchaseFilters;
    companyOrders: ShopAtWorkPurchaseFilters;
    portalOrders: ShopAtWorkPurchaseFilters;
    inventoryItems: ShopAtWorkInventoryFilters;
    searchTerm: string;
    fromOrderId: FromOrderId;
    scrollRef: RefObject<HTMLDivElement | null>;
}

const reducer = (state: ShopAtWorkState, action: ShopAtWorkAction): ShopAtWorkState => {
    const setOrderFilter = (
        action: UpdateShopOverviewSortingDirection | UpdateShopOverviewSortingType | UpdateShopOverviewFilters,
    ) => {
        const { view, ...filters } = action.payload;
        switch (view) {
            case ShopOverviewView.MyPurchases:
                return { myOrders: { ...state.myOrders, ...filters } };
            case ShopOverviewView.CompanyPurchases:
                return { companyOrders: { ...state.companyOrders, ...filters } };
            case ShopOverviewView.PortalPurchases:
                return { portalOrders: { ...state.portalOrders, ...filters } };
        }
    };

    const setInventoryFilter = (
        action:
            | UpdateInventoryOverviewSortingType
            | UpdateInventoryOverviewSortingDirection
            | UpdateInventoryOverviewFilters,
    ) => {
        const { view, ...filters } = action.payload;
        switch (view) {
            case ShopOverviewView.Inventory:
                return { inventoryItems: { ...state.inventoryItems, ...filters } };
        }
    };
    switch (action.type) {
        case 'UPDATE_SHOP_OVERVIEW_VIEW':
            return {
                ...state,
                shopOverviewView: action.payload,
                searchTerm: '',
                fromOrderId: { orderId: null, previousView: null },
            };
        case 'UPDATE_SHOP_OVERVIEW_SORTING_TYPE':
            return {
                ...state,
                ...setOrderFilter(action),
            };
        case 'UPDATE_SHOP_OVERVIEW_SORTING_DIRECTION':
            return {
                ...state,
                ...setOrderFilter(action),
            };
        case 'UPDATE_SHOP_OVERVIEW_FILTERS':
            return {
                ...state,
                ...setOrderFilter(action),
            };
        case 'UPDATE_INVENTORY_OVERVIEW_SORTING_TYPE':
            return {
                ...state,
                ...setInventoryFilter(action),
            };
        case 'UPDATE_INVENTORY_OVERVIEW_SORTING_DIRECTION':
            return {
                ...state,
                ...setInventoryFilter(action),
            };
        case 'UPDATE_INVENTORY_OVERVIEW_FILTERS':
            return {
                ...state,
                ...setInventoryFilter(action),
            };

        case 'UPDATE_SHOP_OVERVIEW_SEARCH_TERM':
            return {
                ...state,
                searchTerm: action.payload,
            };
        case 'UPDATE_FROM_ORDER_ID':
            return {
                ...state,
                fromOrderId: action.payload,
            };
    }
};

const defaultFilters = {
    sortingType: OrderSortingType.Date,
    sortingDirection: OrderSortDirection.Desc,
    statuses: [],
    payments: [],
    employees: [],
    companies: [],
};

const inventoryFilters = {
    sortingType: InventorySortingType.CreatedDate,
    sortingDirection: InventorySortDirection.Desc,
    statuses: [],
    types: [],
};

const initialState: ShopAtWorkState = {
    shopOverviewView: null,
    myOrders: defaultFilters,
    companyOrders: defaultFilters,
    portalOrders: defaultFilters,
    inventoryItems: inventoryFilters,
    searchTerm: '',
    fromOrderId: { orderId: null, previousView: null },
    scrollRef: createRef(),
};

type ShopAtWorkContext = {
    state: ShopAtWorkState;
    dispatch: Dispatch<ShopAtWorkAction>;
};

export const ShopAtWorkContext = createContext<ShopAtWorkContext>({
    state: initialState,
    dispatch: () => {
        return;
    },
});

const ShopAtWork = (): ReactElement => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [state, dispatch] = useReducer(reducer, { ...initialState, scrollRef });

    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const userIsCompanyAdmin = useUserHasPerm(Perm.CompanyAdmin);

    const { moduleName } = useParams<{ moduleName: string }>();
    const {
        myPurchasesLink,
        companyTransactionsLink,
        portalTransactionsLink,
        settingsLink,
        inventoryLink,
        getModuleName,
    } = useShopAtWorkNavigation();

    return (
        <ShopAtWorkContext.Provider value={{ state, dispatch }}>
            <Card ref={scrollRef}>
                <Content>
                    <Switch>
                        {userIsPortalAdmin ? (
                            <Route path={`/:portalName${inventoryLink}`}>
                                <Inventory />
                            </Route>
                        ) : null}
                        <Route path={`/:portalName${myPurchasesLink}`}>
                            <MyPurchases />
                        </Route>
                        {userIsPortalAdmin || userIsCompanyAdmin ? (
                            <Route path={`/:portalName${companyTransactionsLink}`}>
                                <CompanyPurchases />
                            </Route>
                        ) : null}
                        {userIsPortalAdmin ? (
                            <Route path={`/:portalName${portalTransactionsLink}`}>
                                <PortalPurchases />
                            </Route>
                        ) : null}
                        {userIsPortalAdmin || userIsCompanyAdmin ? (
                            <Route path={`/:portalName${settingsLink}`}>
                                <ShopSettings />
                            </Route>
                        ) : null}
                        {moduleName === getModuleName() && <Redirect to={`/:portalName${myPurchasesLink}`} />}
                    </Switch>
                </Content>
            </Card>
        </ShopAtWorkContext.Provider>
    );
};

export default ShopAtWork;
