import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SidebarMenuItem } from '../../../components';
import useUsersAdminNavigation from './useUsersAdminNavigation';

const UsersAdminDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('Admin', { keyPrefix: 'usersAdmin' });

    const { pathname } = useLocation();
    const { goToUsersLink, goToPreferencesLink, usersLink, preferencesLink } = useUsersAdminNavigation();

    const isUsersSettingsPathName = pathname?.includes(usersLink) || pathname?.includes(preferencesLink);

    return (
        <SidebarMenuItem
            icon="AddressBookIcon"
            isActive={isUsersSettingsPathName}
            onClick={goToUsersLink}
            text={t('companySettingSideBarMenuItem')}
            isReversed
        >
            <SidebarMenuItem
                isActive={pathname?.includes(usersLink)}
                onClick={goToUsersLink}
                text={t('usersSideBarMenuItem')}
            />
            <SidebarMenuItem
                isActive={pathname?.includes(preferencesLink)}
                onClick={goToPreferencesLink}
                text={t('preferencesSideBarMenuItem')}
            />
        </SidebarMenuItem>
    );
};

export default UsersAdminDynamicMenuItem;
