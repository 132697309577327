import { ReactElement } from 'react';
import { ReactComponent as AddressBookIcon } from './AddressBookIcon.svg';
import { ReactComponent as AIGeneratedArticleIcon } from './AIGeneratedArticleIcon.svg';
import { ReactComponent as AlertIcon } from './AlertIcon.svg';
import { ReactComponent as ArrowChevronIcon } from './ArrowChevronIcon.svg';
import { ReactComponent as ArrowIcon } from './ArrowIcon.svg';
import { ReactComponent as ArrowPrimary } from './ArrowPrimary.svg';
import { ReactComponent as ArrowSecondary } from './ArrowSecondary.svg';
import { ReactComponent as ArticleNotFoundIcon } from './ArticleNotFoundIcon.svg';
import { ReactComponent as ArticlesIcon } from './ArticlesIcon.svg';
import { ReactComponent as AscendingSortIcon } from './AscendingSortIcon.svg';
import { ReactComponent as BackArrowIcon } from './BackArrowIcon.svg';
import { ReactComponent as BusinessIcon } from './BusinessIcon.svg';
import { ReactComponent as CalendarIcon } from './CalendarIcon.svg';
import { ReactComponent as CaretDownIcon } from './CaretDownIcon.svg';
import { ReactComponent as CaretLeft } from './CaretLeft.svg';
import { ReactComponent as CaretRightIcon } from './CaretRightIcon.svg';
import { ReactComponent as CheckCircleIcon } from './CheckCircleIcon.svg';
import { ReactComponent as CheckIcon } from './CheckIcon.svg';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { ReactComponent as CompanyIcon } from './CompanyIcon.svg';
import { ReactComponent as CopyToClipboardIcon } from './CopyToClipboardIcon.svg';
import { ReactComponent as CutleryIcon } from './CutleryIcon.svg';
import { ReactComponent as CutleryIconDeactivated } from './CutleryIconDeactivated.svg';
import { ReactComponent as DescendingSortIcon } from './DescendingSortIcon.svg';
import { ReactComponent as DirectionCarIcon } from './DirectionCarIcon.svg';
import { ReactComponent as DotIcon } from './DotIcon.svg';
import { ReactComponent as DoubleChevronRightIcon } from './DoubleChevronRightIcon.svg';
import { ReactComponent as DownloadIcon } from './DownloadIcon.svg';
import { ReactComponent as EllipsisIcon } from './EllipsisIcon.svg';
import { ReactComponent as EmailIcon } from './EmailIcon.svg';
import { ReactComponent as EmptyIcon } from './Empty-icon_3Color.svg';
import { ReactComponent as EmptyStateIcon } from './EmptyState.svg';
import { ReactComponent as EngLanguageIcon } from './EngLanguageIcon.svg';
import { ReactComponent as FavoriteBorderIcon } from './FavoriteBorderIcon.svg';
import { ReactComponent as FilledCaretDownIcon } from './FilledCaretDownIcon.svg';
import { ReactComponent as FilterIcon } from './FilterIcon.svg';
import { ReactComponent as FoodMenuEmptyIcon } from './FoodMenuEmpty_3Colors.svg';
import { ReactComponent as ForbiddenIcon } from './ForbiddenIcon.svg';
import { ReactComponent as GearsIcon } from './GearsIcon.svg';
import { ReactComponent as HomeIcon } from './HomeIcon.svg';
import { ReactComponent as HourGlass } from './HourGlass.svg';
import { ReactComponent as ImageIcon } from './ImageIcon.svg';
import { ReactComponent as InfoCircleIcon } from './InfoCircleIcon.svg';
import { ReactComponent as InfoCircleOutlineIcon } from './InfoCircleOutlineIcon.svg';
import { ReactComponent as InfoIcon } from './InfoIcon.svg';
import { ReactComponent as KeyIcon } from './KeyIcon.svg';
import { ReactComponent as KitchenIcon } from './KitchenIcon.svg';
import { ReactComponent as LoadingIcon } from './LoadingIcon.svg';
import { ReactComponent as LocationIcon } from './LocationIcon.svg';
import { ReactComponent as MailIcon } from './MailIcon.svg';
import { ReactComponent as MedicalCenterIcon } from './MedicalCenterIcon.svg';
import { ReactComponent as MessageIcon } from './MessageIcon.svg';
import { ReactComponent as MessageIconWithContent } from './MessageIconWithContent.svg';
import { ReactComponent as MessageNotificationIcon } from './MessageNotificationIcon.svg';
import { ReactComponent as MinusPrimaryIcon } from './MinusPrimaryIcon.svg';
import { ReactComponent as MoreVertIcon } from './MoreVertIcon.svg';
import { ReactComponent as NoLanguageIcon } from './NoLanguageIcon.svg';
import { ReactComponent as OpenBookIcon } from './OpenBookIcon.svg';
import { ReactComponent as PaperplaneIcon } from './PaperplaneIcon.svg';
import { ReactComponent as PencilIcon } from './PencilIcon.svg';
import { ReactComponent as Person } from './Person.svg';
import { ReactComponent as PersonCheckmark } from './PersonCheckmark.svg';
import { ReactComponent as PersonPlus } from './PersonPlus.svg';
import { ReactComponent as PhoneIcon } from './PhoneIcon.svg';
import { ReactComponent as PinnedItemIcon } from './PinnedItemIcon.svg';
import { ReactComponent as PlusIcon } from './PlusIcon.svg';
import { ReactComponent as PlusPrimaryIcon } from './PlusPrimaryIcon.svg';
import { ReactComponent as ReplyIcon } from './ReplyIcon.svg';
import { ReactComponent as Restore24Icon } from './Restore24Icon.svg';
import { ReactComponent as SearchIcon } from './SearchIcon.svg';
import { ReactComponent as ShopIcon } from './ShopIcon.svg';
import { ReactComponent as ShoppingCartIcon } from './ShoppingCartIcon.svg';
import { ReactComponent as SMSNotificationIcon } from './SMSNotificationIcon.svg';
import { ReactComponent as SortIcon } from './SortIcon.svg';
import { ReactComponent as SubtractButtonIcon } from './SubtractButtonIcon.svg';
import { ReactComponent as SuccessIcon } from './SuccessIcon.svg';
import { ReactComponent as SuccessOrderIcon } from './SuccessOrderIcon.svg';
import { ReactComponent as SuccessStateBestillMoteRequestIcon } from './SuccessStateBestillMoteRequest_3Color.svg';
import { ReactComponent as ThreeDots } from './ThreeDots.svg';
import { ReactComponent as TrailingStarsIcon } from './TrailingStarsIcon.svg';
import { ReactComponent as TrashIcon } from './TrashIcon.svg';
import { ReactComponent as UndoIcon } from './UndoIcon.svg';
import { ReactComponent as UploadOutlineIcon } from './UploadOutlineIcon.svg';
import { ReactComponent as VendorsIcon } from './VendorsIcon.svg';
import { ReactComponent as VendorsSettingsIcon } from './VendorsSettings.svg';
import { ReactComponent as VisibilityOffIcon } from './VisibilityOffIcon.svg';
import { ReactComponent as VisibilityOnIcon } from './VisibilityOnIcon.svg';
import { ReactComponent as VisitorRegistrationKeyIcon } from './VisitorRegistrationKeyIcon.svg';
import { ReactComponent as WiFiActivated } from './WiFiActivated.svg';
import { ReactComponent as WiFiDeactivated } from './WiFiDeactivated.svg';

export type SvgIconName =
    | 'AddressBookIcon'
    | 'AlertIcon'
    | 'ArrowChevronIcon'
    | 'ArrowIcon'
    | 'ArrowPrimary'
    | 'ArrowSecondary'
    | 'ArticleNotFoundIcon'
    | 'ArticlesIcon'
    | 'AscendingSortIcon'
    | 'BackArrowIcon'
    | 'CalendarIcon'
    | 'CaretDown'
    | 'CaretLeft'
    | 'CaretRight'
    | 'CheckCircleIcon'
    | 'CheckIcon'
    | 'CloseIcon'
    | 'CutleryIcon'
    | 'CutleryIconDeactivated'
    | 'DescendingSortIcon'
    | 'DirectionCarIcon'
    | 'DotIcon'
    | 'DoubleChevronRight'
    | 'DownloadIcon'
    | 'EllipsisIcon'
    | 'EmptyIcon'
    | 'EmptyStateIcon'
    | 'FilledCaretDownIcon'
    | 'FilterIcon'
    | 'FoodMenuEmptyIcon'
    | 'ForbiddenIcon'
    | 'GearsIcon'
    | 'HomeIcon'
    | 'HourGlass'
    | 'ImageIcon'
    | 'InfoCircle'
    | 'InfoCircleOutline'
    | 'KeyIcon'
    | 'KitchenIcon'
    | 'LocationIcon'
    | 'MessageIcon'
    | 'MessageIconWithContent'
    | 'MessageNotificationIcon'
    | 'MinusPrimary'
    | 'MoreVertIcon'
    | 'OpenBook'
    | 'PaperplaneIcon'
    | 'PencilIcon'
    | 'PinnedItemIcon'
    | 'Plus'
    | 'PlusPrimary'
    | 'ReplyIcon'
    | 'Restore24Icon'
    | 'Search'
    | 'ShopIcon'
    | 'ShoppingCart'
    | 'SMSNotificationIcon'
    | 'SortIcon'
    | 'SubtractButtonIcon'
    | 'SuccessIcon'
    | 'SuccessStateBestillMoteRequestIcon'
    | 'TrashIcon'
    | 'UndoIcon'
    | 'UploadOutlineIcon'
    | 'VendorsIcon'
    | 'VisibilityOff'
    | 'VisibilityOn'
    | 'VendorsSettingsIcon'
    | 'WiFiActivated'
    | 'WiFiDeactivated'
    | 'PersonCheckmark'
    | 'Person'
    | 'PersonPlus'
    | 'CopyToClipboardIcon'
    | 'SuccessOrderIcon'
    | 'VisitorRegistrationKeyIcon'
    | 'FavoriteBorderIcon'
    | 'CompanyIcon'
    | 'MedicalCenterIcon'
    | 'LoadingIcon'
    | 'TrailingStarsIcon'
    | 'AIGeneratedArticleIcon'
    | 'MailIcon'
    | 'InfoIcon'
    | 'EngLanguageIcon'
    | 'NoLanguageIcon'
    | 'BusinessIcon'
    | 'EmailIcon'
    | 'PhoneIcon'
    | 'ThreeDots';

interface SvgIconProps {
    name: SvgIconName;
    alt?: string;
}
const SvgIcon = ({ name, alt = '' }: SvgIconProps): ReactElement | null => {
    switch (name) {
        case 'AddressBookIcon': {
            return <AddressBookIcon role="img" aria-label={alt} />;
        }
        case 'AlertIcon': {
            return <AlertIcon role="img" aria-label={alt} />;
        }
        case 'ArrowChevronIcon': {
            return <ArrowChevronIcon role="img" aria-label={alt} />;
        }
        case 'ArrowIcon': {
            return <ArrowIcon role="img" aria-label={alt} />;
        }
        case 'ArrowPrimary': {
            return <ArrowPrimary role="img" aria-label={alt} />;
        }
        case 'ArrowSecondary': {
            return <ArrowSecondary role="img" aria-label={alt} />;
        }
        case 'ArticleNotFoundIcon': {
            return <ArticleNotFoundIcon />;
        }
        case 'ArticlesIcon': {
            return <ArticlesIcon role="img" aria-label={alt} />;
        }
        case 'AscendingSortIcon': {
            return <AscendingSortIcon role="img" aria-label={alt} />;
        }
        case 'BackArrowIcon': {
            return <BackArrowIcon role="img" aria-label={alt} />;
        }
        case 'CalendarIcon': {
            return <CalendarIcon role="img" aria-label={alt} />;
        }
        case 'CaretDown': {
            return <CaretDownIcon role="img" aria-label={alt} />;
        }
        case 'CaretLeft': {
            return <CaretLeft role="img" aria-label={alt} />;
        }
        case 'CaretRight': {
            return <CaretRightIcon role="img" aria-label={alt} />;
        }
        case 'CheckCircleIcon': {
            return <CheckCircleIcon role="img" aria-label={alt} />;
        }
        case 'CheckIcon': {
            return <CheckIcon role="img" aria-label={alt} />;
        }
        case 'CloseIcon': {
            return <CloseIcon role="img" aria-label={alt} />;
        }
        case 'CutleryIcon': {
            return <CutleryIcon role="img" aria-label={alt} />;
        }
        case 'CutleryIconDeactivated': {
            return <CutleryIconDeactivated role="img" aria-label={alt} />;
        }
        case 'DescendingSortIcon': {
            return <DescendingSortIcon role="img" aria-label={alt} />;
        }
        case 'DirectionCarIcon': {
            return <DirectionCarIcon role="img" aria-label={alt} />;
        }
        case 'DotIcon': {
            return <DotIcon role="img" aria-label={alt} />;
        }
        case 'ThreeDots': {
            return <ThreeDots role="img" aria-label={alt} />;
        }
        case 'DoubleChevronRight': {
            return <DoubleChevronRightIcon role="img" aria-label={alt} />;
        }
        case 'DownloadIcon': {
            return <DownloadIcon role="img" aria-label={alt} />;
        }
        case 'EllipsisIcon': {
            return <EllipsisIcon role="img" aria-label={alt} />;
        }
        case 'EmptyIcon': {
            return <EmptyIcon role="img" aria-label={alt} />;
        }
        case 'EmptyStateIcon': {
            return <EmptyStateIcon role="img" aria-label={alt} />;
        }
        case 'FilledCaretDownIcon': {
            return <FilledCaretDownIcon role="img" aria-label={alt} />;
        }
        case 'FilterIcon': {
            return <FilterIcon role="img" aria-label={alt} />;
        }
        case 'FoodMenuEmptyIcon': {
            return <FoodMenuEmptyIcon role="img" aria-label={alt} />;
        }
        case 'ForbiddenIcon': {
            return <ForbiddenIcon role="img" aria-label={alt} />;
        }
        case 'GearsIcon': {
            return <GearsIcon role="img" aria-label={alt} />;
        }
        case 'HomeIcon': {
            return <HomeIcon role="img" aria-label={alt} />;
        }
        case 'HourGlass': {
            return <HourGlass role="img" aria-label={alt} />;
        }
        case 'ImageIcon': {
            return <ImageIcon role="img" aria-label={alt} />;
        }
        case 'InfoCircle': {
            return <InfoCircleIcon role="img" aria-label={alt} />;
        }
        case 'InfoCircleOutline': {
            return <InfoCircleOutlineIcon role="img" aria-label={alt} />;
        }
        case 'KeyIcon': {
            return <KeyIcon role="img" aria-label={alt} />;
        }
        case 'KitchenIcon': {
            return <KitchenIcon role="img" aria-label={alt} />;
        }
        case 'LocationIcon': {
            return <LocationIcon role="img" aria-label={alt} />;
        }
        case 'MessageIcon': {
            return <MessageIcon role="img" aria-label={alt} />;
        }
        case 'MessageIconWithContent': {
            return <MessageIconWithContent role="img" aria-label={alt} />;
        }
        case 'MessageNotificationIcon': {
            return <MessageNotificationIcon role="img" aria-label={alt} />;
        }
        case 'MinusPrimary': {
            return <MinusPrimaryIcon role="img" aria-label={alt} />;
        }
        case 'MoreVertIcon': {
            return <MoreVertIcon role="img" aria-label={alt} />;
        }
        case 'OpenBook': {
            return <OpenBookIcon role="img" aria-label={alt} />;
        }
        case 'PaperplaneIcon': {
            return <PaperplaneIcon role="img" aria-label={alt} />;
        }
        case 'PencilIcon': {
            return <PencilIcon role="img" aria-label={alt} />;
        }
        case 'PinnedItemIcon': {
            return <PinnedItemIcon role="img" aria-label={alt} />;
        }
        case 'Plus': {
            return <PlusIcon role="img" aria-label={alt} />;
        }
        case 'PlusPrimary': {
            return <PlusPrimaryIcon role="img" aria-label={alt} />;
        }
        case 'ReplyIcon': {
            return <ReplyIcon role="img" aria-label={alt} />;
        }
        case 'Restore24Icon': {
            return <Restore24Icon role="img" aria-label={alt} />;
        }
        case 'Search': {
            return <SearchIcon role="img" aria-label={alt} />;
        }
        case 'ShopIcon': {
            return <ShopIcon role="img" aria-label={alt} />;
        }
        case 'ShoppingCart': {
            return <ShoppingCartIcon role="img" aria-label={alt} />;
        }
        case 'SMSNotificationIcon': {
            return <SMSNotificationIcon role="img" aria-label={alt} />;
        }
        case 'SortIcon': {
            return <SortIcon role="img" aria-label={alt} />;
        }
        case 'SubtractButtonIcon': {
            return <SubtractButtonIcon role="img" aria-label={alt} />;
        }
        case 'SuccessIcon': {
            return <SuccessIcon role="img" aria-label={alt} />;
        }
        case 'SuccessOrderIcon': {
            return <SuccessOrderIcon role="img" aria-label={alt} />;
        }
        case 'SuccessStateBestillMoteRequestIcon': {
            return <SuccessStateBestillMoteRequestIcon role="img" aria-label={alt} />;
        }
        case 'TrashIcon': {
            return <TrashIcon role="img" aria-label={alt} />;
        }
        case 'UndoIcon': {
            return <UndoIcon role="img" aria-label={alt} />;
        }
        case 'UploadOutlineIcon': {
            return <UploadOutlineIcon role="img" aria-label={alt} />;
        }
        case 'VendorsIcon': {
            return <VendorsIcon role="img" aria-label={alt} />;
        }
        case 'VisibilityOff': {
            return <VisibilityOffIcon role="img" aria-label={alt} />;
        }
        case 'VisibilityOn': {
            return <VisibilityOnIcon role="img" aria-label={alt} />;
        }
        case 'WiFiActivated': {
            return <WiFiActivated role="img" aria-label={alt} />;
        }
        case 'WiFiDeactivated': {
            return <WiFiDeactivated role="img" aria-label={alt} />;
        }
        case 'PersonCheckmark': {
            return <PersonCheckmark role="img" aria-label={alt} />;
        }
        case 'Person': {
            return <Person role="img" aria-label={alt} />;
        }
        case 'PersonPlus': {
            return <PersonPlus role="img" aria-label={alt} />;
        }
        case 'CopyToClipboardIcon': {
            return <CopyToClipboardIcon role="img" aria-label={alt} />;
        }
        case 'VisitorRegistrationKeyIcon': {
            return <VisitorRegistrationKeyIcon role="img" aria-label={alt} />;
        }
        case 'FavoriteBorderIcon': {
            return <FavoriteBorderIcon role="img" aria-label={alt} />;
        }
        case 'MedicalCenterIcon': {
            return <MedicalCenterIcon role="img" aria-label={alt} />;
        }
        case 'CompanyIcon': {
            return <CompanyIcon role="img" aria-label={alt} />;
        }
        case 'LoadingIcon': {
            return <LoadingIcon role="img" aria-label={alt} />;
        }
        case 'TrailingStarsIcon': {
            return <TrailingStarsIcon role="img" aria-label={alt} />;
        }
        case 'AIGeneratedArticleIcon': {
            return <AIGeneratedArticleIcon role="img" aria-label={alt} />;
        }
        case 'MailIcon': {
            return <MailIcon role="img" aria-label={alt} />;
        }
        case 'InfoIcon': {
            return <InfoIcon role="img" aria-label={alt} />;
        }
        case 'EngLanguageIcon': {
            return <EngLanguageIcon role="img" aria-label={alt} />;
        }
        case 'NoLanguageIcon': {
            return <NoLanguageIcon role="img" aria-label={alt} />;
        }
        case 'BusinessIcon': {
            return <BusinessIcon role="img" aria-label={alt} />;
        }
        case 'EmailIcon': {
            return <EmailIcon role="img" aria-label={alt} />;
        }
        case 'PhoneIcon': {
            return <PhoneIcon role="img" aria-label={alt} />;
        }
        case 'VendorsSettingsIcon': {
            return <VendorsSettingsIcon role="img" aria-label={alt} />;
        }
        default: {
            return null;
        }
    }
};

export default SvgIcon;
