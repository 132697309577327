import { ReactElement, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { FDVIntegrationTypes, TicketType, TypeIntegrationDto } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { GhostPrimaryButton, Modal, ModalType, PrimaryButton, RadioButton } from '../../../../../../components';
import { FlexBox, Typography } from '../../../../../../components/styleds';
import { useUpdateTicketType } from '../../../../apiQueries/useTicketingType';

const FDVStatusList = styled.ul`
    list-style: none;
    padding: 0;

    & :not(:last-child) {
        margin-bottom: 0.5rem;
    }
`;

const FDVStatusItem = styled.li`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #d4d6d7;
    padding: 0.714rem 1.142rem 0.714rem 4.571rem;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

interface FDVIntegrationProps {
    ticketType: TicketType;
    integrationsArray?: TypeIntegrationDto[];
}

const FDVIntegration = ({ ticketType, integrationsArray }: FDVIntegrationProps): ReactElement => {
    const [updateType, { isLoading, isSuccess, isError }] = useUpdateTicketType();
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');
    const [integrationId, setIntegrationId] = useState<string | null>('');
    const [showModal, setShowModal] = useState(false);

    const handleChangeIntegration = (): void => {
        updateType([ticketType.id, { integrationId }]);
        setShowModal(false);
    };

    const integrations = useMemo(() => {
        const noIntegrationItem = { id: null, description: t('restrictSubmittersIncative') };
        const preparedIntegrations = integrationsArray
            ? integrationsArray.map((item) => ({
                  id: item.id,
                  description: item.description,
              }))
            : [];
        return [noIntegrationItem, ...preparedIntegrations];
    }, [integrationsArray]);

    const showWarningMessage = (e: SyntheticEvent, id: string | null) => {
        e.stopPropagation();
        setShowModal(true);
        setIntegrationId(id);
    };

    const closeModalHandler = () => setShowModal(false);

    const getIntegrationDescription = (type = ''): string => {
        switch (type) {
            case FDVIntegrationTypes.CuroTech:
                return t('FDVIntegrationCuroTechDesc');
            case FDVIntegrationTypes.Famac:
                return t('FDVIntegrationFamacDesc');
            case FDVIntegrationTypes.None:
                return t('FDVIntegrationNoFDV');
            default:
                return t('FDVIntegrationDefaultDesc', { type });
        }
    };

    return (
        <>
            <Modal
                type={ModalType.Small}
                open={showModal}
                title={t('changeOfFDVIntegrationTitle')}
                bottomContent={
                    <FlexBox justify={'flex-end'}>
                        <GhostPrimaryButton onClick={closeModalHandler}>{tCommon('cancelButton')}</GhostPrimaryButton>
                        <StyledPrimaryButton onClick={handleChangeIntegration}>
                            {tCommon('continue')}
                        </StyledPrimaryButton>
                    </FlexBox>
                }
                onClose={closeModalHandler}
                hideCloseButton
            >
                <FlexBox pd={'1.7rem'} dir={'column'} justify={'center'}>
                    <Typography>{t('changeOfFDVIntegrationContent')}</Typography>
                </FlexBox>
            </Modal>
            <FlexBox dir={'column'} mrB={'1.714rem'}>
                <Typography fontSize="1.285rem" lineHeight="1.714rem" fontWeight={900}>
                    {t('FDVIntegrationHeader')}
                </Typography>
                <Typography fontSize="1.142rem" lineHeight={'1.714rem'}>
                    {t('FDVIntegrationInfo')}
                </Typography>
            </FlexBox>
            <FlexBox justify={'flex-end'} mrB={'0.5rem'}>
                {isLoading && (
                    <FlexBox>
                        <Loader active inline size="mini" />
                    </FlexBox>
                )}
                {isSuccess && t('FDVIntegrationSuccessMessage')}
                {isError && (
                    <Typography fontSize="0.857rem" lineHeight={'1.142rem'} color="var(--error-color)">
                        {t('FDVIntegrationErrorMessage')}
                    </Typography>
                )}
            </FlexBox>
            <FDVStatusList>
                {integrations?.map((integration) => {
                    return (
                        <FDVStatusItem key={integration.id} onClick={(e) => showWarningMessage(e, integration.id)}>
                            <div>
                                <Typography
                                    fontSize="0.857rem"
                                    lineHeight={'1.428rem'}
                                    textTransform="uppercase"
                                    letterSpacing={'1px'}
                                >
                                    {integration.description}
                                </Typography>
                                <Typography fontSize="0.857rem" lineHeight={'1.142rem'} color="#677074">
                                    {getIntegrationDescription(integration.description)}
                                </Typography>
                            </div>
                            <RadioButton
                                name={integration.description}
                                value={integration.id || ''}
                                checked={ticketType.integrationId === integration.id}
                            />
                        </FDVStatusItem>
                    );
                })}
            </FDVStatusList>
        </>
    );
};
export default FDVIntegration;
